<template>
  <widget-modal v-bind="modalProps">
    <template #activator="{on}">
      <summary-ai-histogram
        v-if="type === 'agreement-importance' || hasAISummary"
        v-bind="$props"
        @click.stop="on.click"
      />
      <v-btn v-else-if="type === 'text-entry'" v-on="on" color="primary" small>
        <v-icon left>mdi-message-outline</v-icon>
        {{ item.response_count }}
        Comments
      </v-btn>
      <v-btn
        v-else-if="['multiple-select', 'multiple-choice'].includes(type)"
        v-on="on"
        color="primary"
        small
      >
        View Responses
      </v-btn>
      <summary-liklihood-histogram
        v-else-if="type === 'likelihood'"
        v-bind="$props"
        v-on="on"
      />
      <div v-else-if="type === 'yes-no'" class="yesNo" v-on="on">
        <span>Yes&nbsp;</span>
        <div class="bars" :set="([n, y] = item.response_summary)">
          <div
            class="bar-yes"
            :style="{ width: `${y}%` }"
            :class="{ smaller: y < n }"
          >
            {{ roundPrct(y) }}
          </div>
          <div
            class="bar-no"
            :style="{ width: `${n}%` }"
            :class="{ smaller: y > n }"
          >
            {{ roundPrct(n) }}
          </div>
        </div>
        <span>&nbsp;No</span>
      </div>
    </template>
  </widget-modal>
</template>

<script>
import { filterObject, get, matchesLoosely } from "@cp/utils/objectUtils";
import { nonEmpty } from "@cp/utils/itertees";
import { roundPrct } from "@/lib/formats";

const employeeReportRoutes = [
  { name: "report", params: { id: "employee-engagement" } },
  { params: { widget: "employee_survey_report" } },
];

export default {
  props: {
    item: { type: Object, required: true },
    table: { type: Object, required: true },
  },
  computed: {
    id() {
      return this.item.id || this.item.m_id;
    },
    type() {
      return this.item.question_type_slug;
    },
    hasAISummary() {
      return (
        get(this.item, "response_summary.agreement") &&
        get(this.item, "response_summary.importance")
      );
    },
    modalProps() {
      const filterValues = this.$store.getters[this.table.p.g.filterValues];
      const isEmployeeReport = employeeReportRoutes.some(route =>
        matchesLoosely(route, this.$route)
      );
      const questionReportKey = isEmployeeReport
        ? "employee_question_report"
        : "resident_question_report";

      const urls = {
        report: `/reports/${questionReportKey}/${this.id}`,
        region: `/regions/${this.$route.params.id}/${questionReportKey}/${this.id}`,
        property: `/properties/${this.$route.params.id}/${questionReportKey}/${this.id}`,
      };
      const url = urls[this.$route.name];

      return {
        url,
        params: {
          ...filterObject(filterValues, nonEmpty),
          // do not pass q_text down to modals
          q_text: "",
          scope: "responses",
        },
      };
    },
  },
  methods: {
    roundPrct,
  },
};
</script>

<style lang="scss" scoped>
.yesNo {
  display: flex;
  align-items: center;
  min-width: 160px;

  .bars {
    min-width: 40px;
    flex-grow: 1;
    text-align: center;
    position: relative;
    line-height: 2.2em;
    display: flex;

    > div {
      padding: 0 4px;
      white-space: nowrap;
      overflow: visible;
      z-index: 1;
      &.smaller {
        z-index: 2;
      }
    }

    .bar-yes {
      background: $info;
      color: white;
    }
    .bar-no {
      background: $gray-3;
      color: white;
    }
  }
}
</style>
