<template>
  <main>
    <cp-app
      :navItems="navItems"
      :mobileBreakpoint="960"
      :disableResponsiveBelowBreakpoint="false"
      show-loading
    >
      <template #modals>
        <task-status-form />
        <resident-survey-result />
        <employee-form />
      </template>
    </cp-app>
  </main>
</template>

<script>
import { mapState, mapGetters, mapActions } from "vuex";
import cloneDeep from "clone-deep";

import { onCpEvent } from "@cp/mixins";
import { findByObj } from "@cp/utils/arrayUtils";
import { isProduction } from "@cp/constants";

const sidebarClientIdWhitelist = [
  "990fb2a3-f23c-4448-a512-03e4ff806c84", // 01 Swift Bunny
  "47dc2b24-b5c8-4185-97a5-f9f98519e4c2", // 03 Swift Bunny Testing
  "0c562aef-b151-46c6-9bbd-2853f467476f", // XBunnyOne
  "f96b6443-dfdc-4b3a-a4af-96af36f73196", // Faux Phillips
  "49ceed2c-e7f1-4b7d-9903-16aed43b0dd0", // Appartment Dynamics
];

export default {
  name: "Bigwig-ui",

  mixins: [
    onCpEvent("auth/signedIn", function() {
      this.checkHaystackUser();
    }),
  ],

  computed: {
    ...mapGetters("auth", ["userIsSwiftbunny", "clientHasService"]),
    ...mapGetters("haystackUser", [
      "isImpersonatingUser",
      "thisAppAccessLevel",
      "clientId",
      "managedId",
      "identity",
    ]),
    navItems() {
      const nav = {
        home: {
          text: "Home",
          icon: "home-account",
          to: "/",
        },
        // If changed, need to also edit @/components/portfolio/PortfolioBreadcrumbs.vue
        property: {
          text: "Property",
          icon: "home-city",
          to: `/property/${this.managedId}`,
          roleRequired: "property_manager",
        },
        region: {
          text: "Region",
          icon: "home-city",
          to: `/region/${this.managedId}`,
          roleRequired: "regional_manager",
        },
        portfolio: {
          text: "Portfolio",
          icon: "home-city",
          to: { name: "portfolio" },
          roleRequired: [
            /* Add other roles that get portfolio HERE */
            "client-admin",
            "reporting",
            "multi_property_manager",
            "multi_regional_manager",
          ],
        },
        // If changed, need to also edit @/components/portfolio/PortfolioBreadcrumbs.vue
        team: {
          text: "Team",
          icon: "account-group",
          to: { name: "team" },
          roleForbidden: "employee",
        },
        residents: {
          text: "Residents",
          icon: "home-account",
          to: { name: "residents" },
          roleForbidden: "employee",
          serviceRequired: "intouch",
        },
        reports: {
          text: "Reports",
          cpIcon: "histogram",
          to: { name: "reports" },
          requiresReports: true,
          children: [
            {
              text: "Employee Engagement",
              to: "/report/employee-engagement",
              reportRequired: "employee_survey_report",
            },
            {
              text: "Employee Priorities",
              to: "/report/employee-priorities",
              reportRequired: "employee_priority_report",
            },
            // {
            //   text: "Employee Action Items",
            //   to: "/report/employee-action-items",
            //   reportRequired: "employee_action_item_report",
            // },
            {
              text: "Employee Tenure",
              to: "/report/employee-tenure",
              reportRequired: "employee_tenure",
            },
            {
              text: "Employee Turnover",
              to: "/report/employee-turnover",
              reportRequired: "employee_tenure",
            },
            {
              text: "Resident Satisfaction",
              to: "/report/resident-summary",
              reportRequired: "resident_satisfaction_report",
            },
            {
              text: "Reasons Residents Are Leaving",
              to: "/report/resident-reasons-leaving",
              reportRequired: "resident_reasons_leaving_report",
            },
            {
              text: "Task Compliance",
              to: "/report/task-compliance-report",
              reportRequired: "compliance_summary_report",
            },
            {
              text: "Enrollment",
              to: "/report/enrollment-report",
              reportRequired: "enrollment_summary_report",
            },
          ],
        },
      };

      if (this.userIsSwiftbunny && !this.isImpersonatingUser)
        return [nav.home, nav.portfolio, nav.team, nav.residents, nav.reports];

      if (isProduction && !sidebarClientIdWhitelist.includes(this.clientId))
        return [];

      const navOrder = [
        nav.property,
        nav.region,
        nav.portfolio,
        nav.team,
        nav.residents,
        nav.reports,
      ];

      // minimally replicate @cp/mixins/filterByRoleAndService.js for haystackUser

      const rootNavItems = cloneDeep(navOrder)
        .filter(
          ({
            roleRequired,
            roleForbidden,
            serviceRequired,
            requiresReports,
          }) => {
            if (serviceRequired && !this.clientHasService(serviceRequired))
              return false;
            // if client-admin || reporting role return true;
            if (
              roleForbidden &&
              roleForbidden.includes(this.thisAppAccessLevel)
            )
              return false;
            if (roleRequired)
              return roleRequired.includes(this.thisAppAccessLevel);
            if (requiresReports) return this.identity.reports.length;
            return true;
          }
        )
        .map(({ roleRequired, roleForbidden, serviceRequired, ...x }) => x);

      rootNavItems.forEach(nav => {
        if (nav.children)
          nav.children = nav.children
            .filter(({ serviceRequired, reportRequired }) => {
              const serviceAllowed = serviceRequired
                ? this.clientHasService(serviceRequired)
                : true;
              const reportAllowed = reportRequired
                ? findByObj(this.identity.reports, { url_key: reportRequired })
                : true;
              return serviceAllowed && reportAllowed;
            })
            .map(({ serviceRequired, reportRequired, ...x }) => x);
      });

      if (rootNavItems.length) rootNavItems.unshift(nav.home);

      return rootNavItems;
    },
  },
  methods: {
    ...mapActions("haystackUser", { checkHaystackUser: "check" }),
    ctrlShiftQuestionDevMode(e) {
      console.log(e);
      if (e.shiftKey && e.ctrlKey && e.key === "?") {
        this.$store.state.devMode = !this.$store.state.devMode;
        this.$CpEvent.$emit("snackAlert", {
          message: `Developer mode ${
            this.$store.state.devMode ? "enabled" : "disabled"
          }`,
          color: "success",
          icon: "mdi-code-array",
          timeout: 500,
        });
      }
    },
  },
  mounted() {
    this.checkHaystackUser();
    if (this.$store.getters["auth/userIsSwiftbunny"]) {
      document.addEventListener("keyup", this.ctrlShiftQuestionDevMode);
    }
  },
  beforeDestroy() {
    document.removeEventListener("keyup", this.ctrlShiftQuestionDevMode);
  },
};
</script>

<style lang="scss">
@import url("https://fonts.googleapis.com/css?family=Poppins:300,400,500,700,800|Lato:300,300i,400,700,900|Roboto+Condensed:300,400,700&display=swap");
#app .AppBar .appLogoName {
  width: 150px;
}
</style>
